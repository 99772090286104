@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap");
@import "intl-tel-input/build/css/intlTelInput.css";
@import "sweetalert2/src/sweetalert2.scss";

:root {
  --accent: #47c8cb;
  --accent-alpha: rgba(255, 187, 140, 0.47);
  --color-white: #fff;
  --color-blue: #122948;
  --light-grey-color: #d5d5d5;
  --brand-color: #133d59;
  --radius: 10px;
}

*,
*::before,
*::after {
  position: relative;
}

body {
  font-size: 16px;
}

input {
  border-radius: var(--radius);
  border: none;
  transition: box-shadow 0.3s;
  background: #eee;
  padding: 1em;

  &:focus,
  &:active {
    box-shadow: 0 0 7px var(--accent-alpha);
    background: white;
    outline: none;
  }
}

body .flatpickr-calendar.open {
  font-family: "Nunito", sans-serif;
  border: none;
  filter: drop-shadow(35px 45px 73px rgba(32, 32, 35, 0.07));
  box-shadow: none;
}

/*** calendar ***/

.flatpickr-months .flatpickr-month {
  color: var(--brand-color) !important;
}

.flatpickr-prev-month span,
.flatpickr-next-month span {
  display: none;
}

body span.flatpickr-weekday {
  color: #133d59;
}

body {
  .flatpickr-day {
    color: #133d59;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  button,
  a,
  hr,
  div,
  ul,
  ol,
  li {
    white-space: pre-line;
  }

  .toonie-conversational-form {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    button,
    a,
    hr,
    div,
    ul,
    ol,
    li {
      white-space: initial;
    }
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay,
  .flatpickr-day:hover {
    color: #fff;
    background: #133d59;
    border-color: #133d59;
  }
}

.ant-picker-suffix {
  svg path {
    fill: var(--accent);
  }
}

/*** input-tel-number ***/

.iti {
  width: 100%;
  border-radius: 30px;

  // reset styles of the conversational-form library for the phone input
  input {
    &::-webkit-input-placeholder {
      color: rgb(158, 158, 158);
    }
    &::-moz-input-placeholder {
      color: rgb(158, 158, 158);
    }
    &:-moz-input-placeholder {
      color: rgb(158, 158, 158);
    }
    &:-ms-input-placeholder {
      color: rgb(158, 158, 158);
    }

    &:hover {
      &::-webkit-input-placeholder {
        color: rgb(158, 158, 158);
      }
      &::-moz-input-placeholder {
        color: rgb(158, 158, 158);
      }
      &:-moz-input-placeholder {
        color: rgb(158, 158, 158);
      }
      &:-ms-input-placeholder {
        color: rgb(158, 158, 158);
      }
    }
  }

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--accent) --light-grey-color;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: var(--light-grey-color);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--accent);
    border-radius: 20px;
  }
}

.iti__country {
  padding: 0.25em 0 !important;
  font-size: 14px;
}

.iti-mobile .iti--container {
  top: 70%;
  left: 12vw;
  right: auto;
  max-width: 280px;
}

.iti__country-list {
  padding: 0.5em !important;
  width: 280px;
  height: 300px;
}

.intl-phone-number {
  border-radius: 30px;
}

.iti__flag {
  background-image: url("./assets/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("./assets/flags.png");
  }
}

.iti__flag-container .iti__selected-flag {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

/*** end of input-tel-number styles ***/

.ant-form-item-explain.ant-form-item-explain-error {
  text-align: left;
  position: absolute;
  white-space: nowrap;
  top: 100%;
}

.ant-form-item-with-help {
  margin-bottom: 24px;
}

a {
  color: #133d59;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.ant-modal-content {
  padding: 1.5em;
  text-align: center;
  box-shadow: none;
  border-radius: 50px;
}

/* color reset for antd radio buttons */

.ant-radio-checked .ant-radio-inner::after {
  background-color: var(--accent);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--accent);
}
.ant-radio-checked::after {
  border-color: var(--accent);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--accent);
}

/* ant buttons reset */
.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--accent) !important;
}

// Styles for popup that appear when user is not authorized by company at registration
.auth-popup-btn {
  border-radius: 100px !important;
  padding: 0.5em 3em !important;
}

.auth-popup-title {
  color: var(--accent);
  font-family: "Encode Sans Expanded", sans-serif;
  font-size: 1.375rem;
}

.auth-popup-container {
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 2.5rem;
  text-align: center;
  letter-spacing: 2px;
  color: var(--brand-color);

  @media (max-width: 1024px) {
    font-size: 1rem;
    line-height: 2rem;
  }
}

.auth-popup {
  min-width: 800px !important;
  border-radius: 50px;

  @media (max-width: 1024px) {
    min-width: auto !important;
  }
}

/* styles for block back step modal   **/
.block-modal_leave-btn {
  color: var(--accent) !important;
  border: 1px solid var(--accent) !important;
}

.block-modal_leave-btn:hover {
  background-color: var(--accent) !important;
  color: var(--color-white) !important;
}

// Swal Modal Styles

.swal2-popup {
  border-radius: 0 !important;
}

// loading Component

.ant-spin-dot-item {
  background-color: #47c8cb;
}

// Styles for user registration form dialog submit and edit buttons - starts
.submit-form-btn,
.edit-form-btn {
  border-radius: 0 !important;
  min-width: 12.5rem !important;
  padding: 0 !important;
}

.edit-form-btn {
  color: var(--color-blue) !important;
  border-color: var(--color-blue) !important;
}
.edit-form-btn:hover {
  color: var(--color-white) !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

// Styles for user registration form dialog submit and edit buttons - ends
